import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { ItemPayMethodOption, QRCodeBoard } from '@components/_index';

import {
    CURRENCIES_AVAILABLE_INFO,
    TRANSACTION_TYPE
} from '@constants/constants';
import { PAY_TYPE_INFO } from '@constants/payType';

import { getPayType } from '@utils/utils';

export default function useRecipientInfos() {
    const { t } = useTranslation();
    const { transactionType } = useParams();
    const { tradingData } = useSelector((state) => state.order);
    const { isPayMethodUnderTraderName } = useSelector(
        (state) => state.displaySetting
    );

    const infosTitle =
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
            ? t('buyProcess.recipientInfosTitle')
            : t('sellProcess.recipientInfosTitle');

    const payType = getPayType(tradingData);
    const IconPayment =
        tradingData.legalCurrency === CURRENCIES_AVAILABLE_INFO.VND.NAME &&
        payType === PAY_TYPE_INFO.BANKPAY.NAME
            ? null
            : PAY_TYPE_INFO[payType].PayIconComponent;

    const paymentMethodFieldProps = {
        payMethodName: t(`common.paymentMethod.${payType.toLowerCase()}.name`),
        IconPayment,
        label: payType.toLowerCase()
    };

    const payMethodInfo = {
        fieldLabel: 'paymentMethods',
        fieldName: t('common.paymentMethod.desc'),
        renderFieldValue: () => (
            <Box className="field payMethodOptions">
                <ItemPayMethodOption {...paymentMethodFieldProps} />
            </Box>
        )
    };

    let traderInfos = [
        {
            fieldLabel: 'sellerName',
            fieldName: t(
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? 'common.orderInfos.sellerName'
                    : 'common.orderInfos.buyerName'
            ),
            isShowCopyBtn: true,
            isValueBold: true,
            tagNames: [
                t('common.orderInfos.accountVerifyTag.autonym'),
                t('common.orderInfos.accountVerifyTag.guarantee'),
                t('common.orderInfos.accountVerifyTag.quickConfirm')
            ]
        }
    ];

    if (isPayMethodUnderTraderName) {
        traderInfos = [...traderInfos, payMethodInfo];
    }

    if (
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY &&
        tradingData.alipayAccountNo &&
        tradingData.toAddress
    ) {
        const renderAddressQRCode = () => {
            return (
                <Box className="field qrCode">
                    <QRCodeBoard fieldValue={tradingData.toAddress} />
                </Box>
            );
        };

        traderInfos = [
            ...traderInfos,
            {
                fieldLabel: 'qrCode',
                fieldName: t('common.paymentMethod.paymentQRCode'),
                renderFieldValue: renderAddressQRCode
            },
            {
                fieldLabel: 'toAddress',
                fieldName: t('buyProcess.toAddress'),
                isShowCopyBtn: true
            }
        ];
    }

    let accountInfos = [];

    if (!isPayMethodUnderTraderName) {
        accountInfos = [...accountInfos, payMethodInfo];
    }

    if (tradingData.alipayAccountNo) {
        const renderAlipayQRCode = () => {
            return (
                <Box className="field qrCode">
                    <QRCodeBoard fieldValue={tradingData.alipayAccountQrlink} />
                </Box>
            );
        };

        accountInfos = [
            ...accountInfos,
            {
                fieldLable: 'alipayAccountQrlink',
                fieldName: t('common.paymentMethod.alipay.alipayqr'),
                renderFieldValue: renderAlipayQRCode
            },
            {
                fieldLabel: 'alipayAccountNo',
                fieldName: t('common.paymentMethod.alipay.alipayAccountNo'),
                isValueBold: true,
                isShowCopyBtn: true
            },
            {
                fieldLabel: 'alipayAccountName',
                fieldName: t('common.paymentMethod.alipay.alipayAccountName'),
                isValueBold: true
            }
        ];
    } else {
        accountInfos = [
            ...accountInfos,
            {
                fieldLabel: 'bankAccountNo',
                fieldName: t('common.paymentMethod.bankpay.bankAccountNo'),
                isValueBold: true,
                isShowCopyBtn: true
            },
            {
                fieldLabel: 'bankAccountName',
                fieldName: t('common.paymentMethod.bankpay.bankAccountName'),
                isValueBold: true
            },
            {
                fieldLabel: 'bankName',
                fieldName: t('common.paymentMethod.bankpay.bankName'),
                isValueBold: true
            },
            {
                fieldLabel: 'bankBranchName',
                fieldName: t('common.paymentMethod.bankpay.bankBranchName'),
                isValueBold: true
            }
        ];
    }

    const remarkMsgs = [
        t('buyProcess.paying.transferRemark1'),
        t('buyProcess.paying.transferRemark2')
    ];

    return {
        tradingData,
        transactionType,
        infosTitle,
        traderInfos,
        accountInfos,
        remarkMsgs
    };
}
