import { Payment } from '@mui/icons-material';
import { Box } from '@mui/material';

import '@template/components/ItemFieldPart/ItemPayMethodOption/ItemPayMethodOption.scss';

import '@styles/common/ItemPayMethodOption.scss';

export default function ItemPayMethodOption({
    label,
    payMethodName,
    IconPayment
}) {
    return (
        <Box
            className={`ItemPayMethodOption ${label}`}
            color="primary"
            variant="outlined">
            {IconPayment ? (
                <IconPayment className={`payIcon ${label}`} alt={label} />
            ) : (
                <Payment />
            )}
            <Box className="payMethodName">{payMethodName}</Box>
        </Box>
    );
}
